<template>
  <div>
    <div v-if="batch.status === 'error' && batch.code" class="alert alert-danger">
      <strong>{{ batch.code }}</strong>: {{ $store.state.errorMessages[batch.code] }}
    </div>
    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-12 col-md-8">
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'batches' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Batches</router-link>
              </li>
              <li class="breadcrumb-item active">
                {{ batch && batch.id }}
              </li>
            </ul>
          </div>
          <div class="col-12 col-md-4 text-right">
            <batch-download :batchId="batch.id" :visible="visible" @close="visible = false">
              <a-button size="small" :loading="visible !== false" @click="visible = true">
                <i class="fal fa-file-csv mr-2"></i> Export CSV
              </a-button>
            </batch-download>
          </div>
        </div>
      </div>
      <div class="card-body">
        <label class="label">Name</label>
        <p><strong>{{ batch.name }}</strong></p>
        <div class="row">
          <div class="col-6 col-lg-4">
            <label class="label">Status</label>
            <div>
              <span v-if="batch.status === 'pending'"><i class="fad fa-pause-circle text-muted mr-1"></i> Not Started</span>
              <span v-if="batch.status === 'scheduled'"><i class="fad fa-play-circle text-warning mr-1"></i> Queued for Processing</span>
              <span v-if="batch.status === 'processing'"><i class="fad fa-play-circle text-warning mr-1"></i> Processing</span>
              <span v-if="batch.status === 'completed'"><i class="fad fa-stop-circle text-success mr-1"></i> Completed</span>
              <span v-if="batch.status === 'error'"><i class="fad fa-stop-circle text-danger mr-1"></i> Error</span>
            </div>
          </div>
          <div class="col-6 col-lg-4">
            <label class="label">Queries</label>
            <div>{{ batch.queries.length }}</div>
          </div>
          <div class="col-6 col-lg-4">
            <label class="label">Succeeded</label>
            <div>{{ batch.succeeded_count }} <small>({{ percentageComplete }}%)</small></div>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex">
        <batch-start v-if="batch.status === 'pending' || batch.status === 'error'"
          :batchId="batch.id"
          :hits="batch.queries.length - batch.queries_ignored.length"
          :visible="startVisible"
          @cancel="startVisible = false"
          @success="onCreate"
        >
          <a-button type="primary" :disabled="disabled" @click="startVisible = true" class="mr-2">
            Start Validation
          </a-button>
        </batch-start>
        <a-dropdown-button :trigger="['click']" @click="editVisible = true">
          <batch-form
            :batch="batch"
            :visible="editVisible"
            @close="editVisible = false"
            @success="onEdit"
          >
            <i class="fal fa-fw fa-pen"></i> Edit
          </batch-form>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item key="delete">
              <span class="text-danger"><i class="fal fa-fw fa-minus-circle"></i> Delete</span>
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
      </div>
    </div>
  </div>
</template>

<script>
import BatchDownload from '@/components/Batch/BatchDownload'
import BatchForm from '@/components/Batch/BatchForm'
import BatchStart from '@/components/Batch/BatchStart'

export default {
  components: {
    BatchDownload,
    BatchForm,
    BatchStart,
  },

  props: {
    batch: Object,
  },

  data() {
    return {
      loading: false,
      visible: false,
      editVisible: false,
      startVisible: false,
    }
  },

  computed: {
    percentageComplete() {
      return Math.round(this.batch.succeeded_count * 100 / this.batch.queries.length)
    },
    disabled() {
      return (this.batch.queries.length - this.batch.queries_ignored.length) <= 0
    },
  },

  methods: {
    onClick({ key }) {
      switch (key) {
        case 'delete':
          if (this.batch.status === 'pending' || this.batch.status === 'completed') {
            this.$confirm({
              title: 'Delete Batch',
              content: 'This batch will be deleted from your account. All processed validations will remain untouched in your validation history. The action cannot be undone.',
              okText: 'Delete',
              onOk: async () => {
                await this.onDelete()
              },
              onCancel() {},
            })
          } else {
            this.$error({
              title: 'Batch in Progress',
              content: 'Batches that are still in progress cannot be deleted. Please wait until the batch completes.',
              onCancel() {},
            })
          }
        break
      }
    },

    async onDelete() {
      try {
        var { data } = await this.$http.delete(`/batches/${ this.batch.id }`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
        })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      }

      this.$message.success('Batch has been deleted.')
      this.$router.push({ name: 'batches' })
    },

    onCreate() {
      this.startVisible = false
      this.$emit('change')
    },

    onEdit() {
      this.editVisible = false
      this.$emit('change')
    },
  },
}
</script>
