<template>
  <div>
    <BaseSpinner v-if="loading" :loading="loading" />
    <div v-else>
      <BatchOverview
        :batch="batch"
        class="mb-3"
        @change="retrieveBatch"
      />
      <div class="card mb-3">
        <div class="card-header">
          <strong>Queries <span v-if="batch">({{ batch.queries.length }})</span></strong>
        </div>
        <table class="table mb-0">
          <thead class="thead-light">
            <tr>
              <th>Query</th>
              <th>Error Code</th>
              <th>Consultation Number</th>
              <th>Requested</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(validation, i) in batch.validations.validations" :key="i">
              <td>
                <a-tooltip v-if="validation.valid === true" title="Valid" placement="left">
                  <i class="fad fa-check-circle text-success mr-2"></i>
                  <span v-if="validation.id">
                    <router-link :to="{ name: 'validations-id', params: { id: validation.id }}" class="color-inherit">{{ validation.query }}</router-link>
                  </span>
                  <span v-else class="text-muted">{{ validation.query }}</span>
                </a-tooltip>
                <a-tooltip v-else-if="validation.valid === false" title="Invalid" placement="left">
                  <i class="fad fa-times-circle text-danger mr-2"></i>
                  <span v-if="validation.id">
                    <router-link :to="{ name: 'validations-id', params: { id: validation.id }}" class="color-inherit">{{ validation.query }}</router-link>
                  </span>
                  <span v-else class="text-muted">{{ validation.query }}</span>
                </a-tooltip>
                <a-tooltip v-else :title="$store.state.errorMessages[validation.code] || 'Scheduled'" placement="left">
                  <i class="fad fa-clock text-muted mr-2"></i>
                  <span v-if="validation.id">
                    <router-link :to="{ name: 'validations-id', params: { id: validation.id }}" class="color-inherit">{{ validation.query }}</router-link>
                  </span>
                  <span v-else class="text-muted">{{ validation.query }}</span>
                </a-tooltip>
              </td>
              <td>
                <code>{{ validation.code }}</code>
              </td>
              <td>
                {{ validation.consultation_number }}
              </td>
              <td>
                <span v-if="validation.requested">{{ convertDate(validation.requested) }}</span>
              </td>
            </tr>
            <tr v-if="!batch.validations.validations.length && !loading">
              <td class="text-muted text-center" colspan="4">
                <i class="fad fa-empty-set mr-1"></i> No queries have been added yet.
              </td>
            </tr>
          </tbody>
        </table>
        <div class="card-footer">
          <BasePagination
            v-model="filters.page"
            :hasMore="hasMore"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import BatchOverview from '@/components/Batch/BatchOverview'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    BatchOverview,
  },

  data() {
    return {
      loading: true,
      batch: null,
      hasMore: false,
      filters: {
        page: Number(this.$route.query.page) || 1,
      },
    }
  },

  mounted() {
    this.retrieveBatch()
  },

  methods: {
    async retrieveBatch() {
      try {
        var { data } = await this.$http.get(`/batches/${ this.$route.params.id }`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: this.filters,
        })

        this.hasMore = data.validations.has_more
        this.batch = data
      } catch (err) {
        if (err.response) {
          if (err.response.status === 404) {
            this.$router.push({ name: '404' })
          }

          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    convertDate(date) {
      return date && date.toString().slice(0, 10)
    },
  },

  watch: {
    '$store.state.livemode'() {
      this.$router.replace({ name: 'batches' })
    },
    'filters.page'(value) {
      this.$router.push({ name: 'batches-id', params: { id: this.batch.id }, query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
