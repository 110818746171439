<template>
  <div>
    <slot />
    <a-modal
      title="Start Validation"
      :visible="visible"
      @ok="onConfirm"
      okText="Start"
      :confirmLoading="loading"
      @cancel="$emit('cancel')"
    >
      <p>Before you start, please mind the following:</p>
      <ul>
        <li class="mb-2">Once you start the validation process, this batch will be <strong>permanently locked</strong> for further changes.</li>
        <li v-if="$store.state.livemode" class="mb-2">Your consumed hits will <strong>increase by {{ hits }}</strong>. This is the number of validation requests required, excluding ignored queries.</li>
        <li>Validations <strong>may take longer</strong> to complete when government services are not responsive.</li>
      </ul>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    batchId: String,
    hits: Number,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async onConfirm() {
      this.loading = true

      try {
        var { data } = await this.$http.put(`/batches/${ this.batchId }`, {
          status: 'scheduled'
        }, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
        })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$emit('success')
      this.$message.success('Batch has started.')
    },
  },
}
</script>
