<template>
  <div class="card mb-3">
    <div class="card-header d-flex justify-content-between align-items-center">
      <div>
        <strong>Batches <span v-if="batchesCount !== null">({{ batchesCount }})</span></strong>
      </div>
      <ul class="list-inline mb-0">
        <li class="list-inline-item">
          <a href="https://vatstack.com/docs/batches" target="_blank" class="mr-2">API Reference <i class="fal fa-external-link ml-1"></i></a>
        </li>
        <li class="list-inline-item">
          <BatchForm :visible="visible" @close="visible = false">
            <a-button type="primary" @click="visible = true">
              <i class="fal fa-plus mr-2"></i> New Batch
            </a-button>
          </BatchForm>
        </li>
      </ul>
    </div>
    <BaseSpinner :loading="loading">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>Name</th>
            <th>Queries</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="batch in batches"
            :key="batch.id"
          >
            <td>
              <router-link :to="{ name: 'batches-id', params: { id: batch.id }}" class="text-dark">
                <a-tooltip v-if="batch.status === 'pending'" title="Not Started" placement="left">
                  <i class="fad fa-pause-circle text-muted mr-1"></i> {{ batch.name }}
                </a-tooltip>
                <a-tooltip v-if="batch.status === 'scheduled'" title="Queued for Processing" placement="left">
                  <i class="fad fa-play-circle text-warning mr-1"></i> {{ batch.name }}
                </a-tooltip>
                <a-tooltip v-if="batch.status === 'processing'" title="Processing" placement="left">
                  <i class="fad fa-play-circle text-warning mr-1"></i> {{ batch.name }}
                </a-tooltip>
                <a-tooltip v-if="batch.status === 'completed'" title="Completed" placement="left">
                  <i class="fad fa-stop-circle text-success mr-1"></i> {{ batch.name }}
                </a-tooltip>
                <a-tooltip v-if="batch.status === 'error'" title="Error" placement="left">
                  <i class="fad fa-stop-circle text-danger mr-1"></i> {{ batch.name }}
                </a-tooltip>
              </router-link>
            </td>
            <td>{{ batch.queries.length }}</td>
            <td>{{ convertDateTime(batch.created) }}</td>
          </tr>
          <tr v-if="!batchesCount">
            <td class="text-muted text-center" colspan="3">
              <i class="fad fa-empty-set mr-1"></i> No batches were found.
            </td>
          </tr>
        </tbody>
      </table>
    </BaseSpinner>
    <div class="card-footer">
      <BasePagination
        v-model="filters.page"
        :hasMore="hasMore"
        :pageCount="Math.ceil(batchesCount / filters.limit)"
      />
    </div>
  </div>
</template>

<script>
import BasePagination from '@/components/Base/BasePagination'
import BaseSpinner from '@/components/Base/BaseSpinner'
import BatchForm from '@/components/Batch/BatchForm'

export default {
  components: {
    BasePagination,
    BaseSpinner,
    BatchForm,
  },

  data() {
    return {
      loading: false,
      visible: false,
      hasMore: false,
      filters: {
        limit: 20,
        page: Number(this.$route.query.page) || 1,
      },
      batches: [],
      batchesCount: null,
    }
  },

  mounted() {
    this.listBatches()
  },

  methods: {
    async listBatches() {
      try {
        this.loading = true

        var { data } = await this.$http.get(`/batches`, {
          headers: { 'x-api-key': this.$store.getters.secretKey },
          params: { page: this.filters.page },
        })

        this.hasMore = data.has_more
        this.batches = data.batches
        this.batchesCount = data.batches_count
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    convertDateTime(date) {
      return date && `${ date.toString().slice(0, 10) } ${ date.toString().slice(11, 16) }`
    },
  },

  watch: {
    '$store.state.livemode'() {
      if (this.filters.page !== 1) {
        this.$router.replace({ name: 'batches' })
      } else {
        this.listBatches()
      }
    },
    'filters.page'(value) {
      this.$router.push({ name: 'batches', query: { ...this.$route.query, page: value }})
    },
  },
}
</script>
