<template>
  <div>
    <slot />
  </div>
</template>

<script>
import FileSaver from 'file-saver'

export default {
  props: {
    batchId: String,
    visible: Boolean,
  },

  methods: {
    async onConfirm() {
      var response = await this.$httpd.get(`/batches/${ this.batchId }/csv`, {
        responseType: 'blob',
      })

      var content = new Blob([response.data], { type: 'text/plain;charset=utf-8' })

      FileSaver.saveAs(content, 'batch.csv')
    },
  },

  watch: {
    async visible(newValue, oldValue) {
      if (newValue) {
        await this.onConfirm()
        this.$emit('close')
      }
    },
  },
}
</script>
