<template>
  <div>
    <slot />
    <a-modal
      :title="batch ? 'Edit Batch': 'Create Batch'"
      :visible="visible"
      @ok="onConfirm"
      okText="Save"
      :confirmLoading="loading"
      @cancel="$emit('close')"
    >
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="Descriptive Name" :colon="false" required>
          <a-input
            v-decorator="['name', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Name is required.' }],
              }, {
                trigger: 'change',
                rules: [{ min: 3, message: 'Name is too short.' }],
              }]
            }]"
            name="name"
            type="text"
            :placeholder="`VAT ID numbers for ${ monthName }`"
          />
        </a-form-item>
        <div v-if="!batch || (batch && batch.status === 'pending')">
          <a-form-item label="Queries" :colon="false">
            <a-upload v-decorator="['queries']" name="file" accept="text/*" :beforeUpload="beforeUpload" @change="onUpload">
              <a-button :disabled="disabled">
                <i class="fal fa-file-upload mr-2"></i> Select CSV File
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item>
            <a-radio-group v-if="batch && modeVisible" v-decorator="['mode']" name="mode">
              <div style="height: 1.8rem;">
                <a-radio value="append">
                  <a-tooltip placement="right" title="Appends your list of queries to the queries currently saved in your batch. Duplicates will be purged.">
                    <strong>Append</strong> to existing queries (recommended) <i class="fad fa-fw fa-info-circle text-muted"></i>
                  </a-tooltip>
                </a-radio>
              </div>
              <div style="height: 1.8rem;">
                <a-radio value="replace">
                  <a-tooltip placement="right" title="Clears all currently saved queries in your batch and replaces them with your new list of queries.">
                    <strong>Replace</strong> existing queries <i class="fad fa-fw fa-info-circle text-muted"></i>
                  </a-tooltip>
                </a-radio>
              </div>
            </a-radio-group>
          </a-form-item>
          <small>
            <p>Please note that duplicate queries will be <strong>purged</strong>. Invalid VAT ID number formats will be saved for your reference, but <strong>ignored</strong> during validation. See <a href="/sample.csv" target="_blank">sample.csv</a></p>
          </small>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import csv from 'csvtojson'
import moment from 'moment'

export default {
  props: {
    batch: Object,
    visible: Boolean,
  },

  data() {
    return {
      loading: false,
      modeVisible: false,
      disabled: false,
    }
  },

  created() {
    this.form = this.$form.createForm(this, {
      mapPropsToFields: () => {
        if (!this.batch) return

        return {
          name: this.$form.createFormField({ value: this.batch.name }),
          queries: this.$form.createFormField({ value: undefined }),
          mode: this.$form.createFormField({ value: 'append' }),
        }
      },
    })
  },

  computed: {
    monthName() {
      return moment(new Date()).format('MMMM')
    },
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        if (err) {
          return false
        }

        this.loading = true

        try {
          if (this.batch) {
            var { data } = await this.$http.put(`/batches/${ this.batch.id }`, values, {
              headers: { 'x-api-key': this.$store.getters.secretKey },
            })
            this.$emit('success')
          } else {
            var { data } = await this.$http.post(`/batches`, values, {
              headers: { 'x-api-key': this.$store.getters.secretKey },
            })
            this.$router.push({ name: 'batches-id', params: { id: data.id }})
          }
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.$message.success('Batch has been saved.')
      })
    },

    beforeUpload(data) {
      var reader = new FileReader()

      reader.onload = async (e) => {
        var result = await csv({
          output: 'csv',
        })
        .fromString(reader.result)

        var queries = result.map(item => {
          return item[0]
        })

        this.form.setFieldsValue({ queries: queries })
      }
      reader.readAsText(data)

      return false
    },

    onUpload(data) {
      if (data.fileList.length) {
        this.modeVisible = true
        this.disabled = true
      } else {
        this.modeVisible = false
        this.disabled = false
      }
    },
  },
}
</script>
